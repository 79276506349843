var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "AdminDebtorUpload" }, [
    _c(
      "div",
      { staticClass: "row row--align-center row--justify-between mb-17" },
      [
        _vm._m(0),
        _c(
          "base-button",
          {
            staticClass: "bg-blue fc-white",
            on: { click: _vm.triggerFileUpload }
          },
          [_vm._v("\n      Upload Debtors\n    ")]
        ),
        _c(
          "base-button",
          {
            staticClass: "bg-blue fc-white",
            on: { click: _vm.addDebtorRatingHistory }
          },
          [_vm._v("\n      Reset Debtor History Metadata\n    ")]
        )
      ],
      1
    ),
    _c("input", {
      directives: [
        { name: "show", rawName: "v-show", value: false, expression: "false" }
      ],
      ref: "hiddenFileInput",
      attrs: {
        accept: ".csv",
        type: "file",
        id: "Testing__DebtorUploadFileInput"
      },
      on: { change: _vm.uploadDebtors }
    })
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "column column--width-auto" }, [
      _c("label", { staticClass: "fs-18 fw-med" }, [_vm._v("Debtor Upload")]),
      _c("label", { staticClass: "fc-light fs-14" }, [
        _vm._v(
          "\n        Depending on number of debtors to upload, this process could take seconds to many hours\n      "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }