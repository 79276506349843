<template>
  <div class="AdminDebtorUpload">
    <div class="row row--align-center row--justify-between mb-17">
      <div class="column column--width-auto">
        <label class="fs-18 fw-med">Debtor Upload</label>
        <label class="fc-light fs-14">
          Depending on number of debtors to upload, this process could take seconds to many hours
        </label>
      </div>
      <base-button
        @click="triggerFileUpload"
        class="bg-blue fc-white"
      >
        Upload Debtors
      </base-button>

      <base-button
        @click="addDebtorRatingHistory"
        class="bg-blue fc-white"
      >
        Reset Debtor History Metadata
      </base-button>
      <!-- <base-button
        @click="reprocessRules"
        class="bg-blue fc-white"
      >
        Reprocess Upload
      </base-button> -->
    </div>

    <!-- TODO: V2 -->
    <!-- <div class="row row--justify-between">
      <label class="fs-18 fw-med AdminDebtorUpload__label--input-height">Download Upload Reports</label>
      <div class="row row--width-auto">
        <base-date-picker
          v-model="startDate.value"
          class="mr-12"
          :instruction="instructions.UPLOAD_DEBTORS_DOWNLOAD_START_DATE_INSTRUCTION"
          :placeholder="'Start Date'"
          :valid="startDate.valid"
        />

        <base-date-picker
          v-model="endDate.value"
          class="mr-12"
          :instruction="instructions.DATE_INSTRUCTION"
          :placeholder="'End Date'"
          :valid="endDate.valid"
        />

        <base-button
          @click="downloadReports"
          class="bg-blue fc-white"
        >
          Download
        </base-button>
      </div>
    </div> -->

    <!-- HIDDEN -->
    <input
      v-show="false"
      accept=".csv"
      ref="hiddenFileInput"
      type="file"
      id="Testing__DebtorUploadFileInput"
      @change="uploadDebtors"
    >
  </div>
</template>

<script>
// Packages
import * as Papa from 'papaparse'
// Helpers
import { Debtor } from '../../utils/api'
// Components
import BaseButton from '../../components/base-button.vue'
// import BaseDatePicker from '../../components/base-date-picker.vue'
// Mixins
import {
  ValidationMixin
} from '../../utils/validation-mixin'

export default {
  name: 'AdminDebtorUpload',

  mixins: [ValidationMixin],

  components: {
    BaseButton,
    // TODO: V2
    // BaseDatePicker,
  },

  data () {
    return {
      endDate: {
        valid: true,
        value: null,
      },
      startDate: {
        valid: true,
        value: null,
      },
    }
  },

  methods: {
    appendDownloadLink (invoiceReportUrl, fileName) {
      const a = document.createElement('a')
      a.style.display = 'none'
      document.body.appendChild(a)
      a.href = invoiceReportUrl
      a.setAttribute('download', fileName)
      a.setAttribute('target', '_blank')
      a.click()
      window.URL.revokeObjectURL(a.href)
      document.body.removeChild(a)
    },

    async addDebtorRatingHistory () {
      this.alertInfo('The reset process has begun', 'Debtors Resetting')
      try {
        await Debtor.resetDebtorHistoryMetadata()
      }
      catch (error) {
        console.error(error) // I want this reported in production (do not use Clog)
        this.alertError('The reset process did not work', 'Debtors Resetting')
      }
    },

    async downloadChangesToExistingDebtors () {
      const { invoice_report_url, file_name } = (await Debtor.history({
        fields: 'rating',
        returnWithDetails: true,
        updatesOnly: true,
        '>__created_at': this.startDate.value,
        '<__created_at': this.endDate.value,
      })).data
      this.appendDownloadLink(invoice_report_url, file_name)
    },

    async downloadNewDebtors () {
      const { invoice_report_url, file_name } = (await Debtor.history({
        createsOnly: true,
        fields: 'rating',
        returnWithDetails: true,
        '>__created_at': this.startDate.value,
        '<__created_at': this.endDate.value,
      })).data
      this.appendDownloadLink(invoice_report_url, file_name)
    },

    downloadReports () {
      if (!this.validation()) return

      try {
        this.downloadChangesToExistingDebtors()
        this.downloadNewDebtors()
      }
      catch (error) {
        this.CLog(error)
        this.alertError('There was an issue downloading the upload reports')
      }
    },

    reprocessRules() {
      return Debtor.reprocessUpload();
    },

    triggerFileUpload () {
      this.$refs.hiddenFileInput.click()
    },

    async uploadDebtors(event) {
      const component = this
      Papa.parse(event.target.files[0], {
        complete(results, file) {
          const debtors = []
          const debtorUploadTitleToBobtailSchemaMap = {
            mc: 'mc',
            dot: 'dot',
            name: 'name',
            phone: 'phone', // validate phone?
            rating: 'external_rating',
            'street address': 'address',
            city: 'city',
            state: 'state',
            'zip code': 'zip',
            'of carriers reporting': 'external_invoices_total',
            'invoices included last 60 days': 'external_invoices_60',
            'avg days to pay for last 90 days': 'external_days_to_pay_90',
            'invoices included last 90 days': 'external_invoices_90',
            'avg days to pay total': 'external_days_to_pay_total',
            'invoices included last 120 days': 'metadata.external_invoices_120',
            'authority type': 'metadata.authority_type',
            'high credit': 'metadata.high_credit',
          }
          const brokerUploadTitleToBobtailSchemaMap = {
            mc: 'mc',
            'authority granted': 'authority_date',
            'bond company': 'insurance_company_name',
            'bond company contact': 'metadata.insurance_contact',
            'bond policy': 'insurance_policy_number',
            'bond company phone': 'insurance_phone', // validate phone?
            'effective date': 'insurance_effective_date',
          }
          const sheetTitleKeys = results.data
            .shift()
            .map(title => title.replace(/[^\w\s]/gi, '').replace(/\n/g, '').trim().toLowerCase())

          console.log('Parsing complete:', results, file)
          console.log(sheetTitleKeys.join(', '))

          results.data.forEach((row) => {
            const debtor = {
              broker: {
                metadata: {}
              },
              metadata: {}
            }
            row.forEach((fieldValue, index) => {
              const debtorKey = debtorUploadTitleToBobtailSchemaMap[sheetTitleKeys[index]]
              const brokerKey = brokerUploadTitleToBobtailSchemaMap[sheetTitleKeys[index]]

              if (debtorKey) {
                if (debtorKey.includes('metadata')) {
                  debtor.metadata[debtorKey.split('.')[1]] = fieldValue
                } else {
                  debtor[debtorKey] = fieldValue
                }
              } else if (brokerKey) {
                if (brokerKey.includes('metadata')) {
                  debtor.broker.metadata[brokerKey.split('.')[1]] = fieldValue
                } else if (fieldValue && fieldValue.length) {
                  debtor.broker[brokerKey] = fieldValue
                }
              } else {
                debtor.metadata[sheetTitleKeys[index]] = fieldValue
              }
            })
            debtors.push(debtor)
          })

          console.log(debtors)

          Debtor.bulkUpload(debtors)

          component.alertSuccess(
            'The upload process has begun and once complete, you will receive an email notification',
            'Debtors Uploading'
          )
        },
        error(error) {
          component('There was an error uploading the file')
          this.CError(error)
        }
      })
    },

    validation () {
      let valid = true

      // 1. End date must be today or before
      // 2. Start date must be before or the same day as the end date

      if (!this.endDate.value || !this.isValidEndDate(new Date(), this.endDate.value)) {
        valid = false
        this.$set(this.endDate, 'valid', false)
      }
      else {
        this.$set(this.endDate, 'valid', true)
      }

      if (
        !this.startDate.value
        || !this.isValidUploadDebtorsDownloadStartDate(this.startDate.value, this.endDate.value)
      ) {
        valid = false
        this.$set(this.startDate, 'valid', false)
      }
      else {
        this.$set(this.endDate, 'valid', true)
      }

      return valid
    },
  },
}
</script>

<style lang="sass">
.AdminDebtorUpload

  &__label

    &--input-height
      line-height: $input-height
</style>